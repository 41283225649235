import React, { Fragment } from 'react'

import Layout from 'layouts/Page'
import SEO from 'components/SEO/SEO'
import PasswordForgotForm from 'components/Auth/PasswordForgot'

class PasswordForgotPage extends React.Component {
  onSubmit = async email => {
    try {
      await this.props.auth.doPasswordReset(email)
    } catch (error) {
      return error
    }
  }

  render() {
    return (
      <div id="password-forgot-page">
        <div className="content-wrap">
          <section className="page-title form-title">
            <h2>Password Recovery</h2>
          </section>
          <PasswordForgotForm onSubmit={this.onSubmit} />
        </div>
      </div>
    )
  }
}

export default ({ location }) => (
  <Layout navbar="auth" location={location} displayFooter={false}>
    <SEO title="Password Recover" />
    <PasswordForgotPage />
  </Layout>
)
