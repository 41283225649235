import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import * as routes from 'constants/routes'
import { button } from 'utils/styles'
import CustomAlert from 'components/shared/Forms/CustomAlert'

const AuthBtn = styled('button')`
  display: flex;
  flex: 1;
  ${button.purple};
  ${button.big};
`

const INITIAL_STATE = {
  email: '',
  error: null,
  success: false,
}

export default class PasswordForgotForm extends Component {
  state = {
    ...INITIAL_STATE,
  }

  onSubmit = event => {
    event.preventDefault()

    const { email } = this.state
    ;(async () => {
      const error = await this.props.onSubmit(email)
      if (error) {
        this.setState({ error: true, success: false })
      } else {
        this.setState({ success: true, error: null })
      }
    })()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, error, success } = this.state

    const isInvalid = email === ''

    return (
      <form
        role="login-form"
        className="sigin-form"
        id="login-form"
        onSubmit={this.onSubmit}
      >
        <section className="form-page-container">
          <div className="auth-page-subcontainer">
            <div className="auth-page-flex">
              <fieldset className="fieldset fieldset-with-label">
                <label htmlFor="email">Email signed up with</label>
                <input
                  type="email"
                  name="email"
                  autoFocus
                  required
                  id="email"
                  maxLength="250"
                  value={email}
                  onChange={this.onChange}
                />
              </fieldset>

              <section className="auth-page-submit">
                <AuthBtn type="submit" value="Submit">
                  Recover
                </AuthBtn>
              </section>
              <CustomAlert
                type="success"
                visible={success}
                message="A recovery link has successfully been sent to your email"
              />
              <CustomAlert
                type="error"
                visible={error}
                message="There was an error sending the recovery link"
              />
            </div>
          </div>
        </section>
      </form>
    )
  }
}

const PasswordForgotLink = () => (
  <p>
    <Link to={routes.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
)
